import React, {useRef} from "react";
import anime from "animejs";

function About(){
	const aboutButtonRef = useRef();

	const aboutBoxRef = useRef();

	function aboutButtonClick(){
		aboutButtonRef.current.style.visibility = "hidden";

		aboutBoxRef.current.style.visibility = "visible";

		anime({
			targets: aboutBoxRef.current,
			translateX: ["-100%", "0"],
			duration: 200,
			easing: "easeInOutQuad"
		});
	}

	function aboutCloseClick(){
		anime({
			targets: aboutBoxRef.current,
			translateX: ["0", "-100%"],
			duration: 200,
			easing: "easeInOutQuad",

			complete: () => {
				aboutButtonRef.current.style.visibility = "visible";

				aboutBoxRef.current.style.visibility = "hidden";
			}
		});
	}

	return (
		<>
			<div className="aboutButton" onClick={aboutButtonClick} ref={aboutButtonRef}>
				<span className="material-symbols-outlined aboutIcon">
					info
				</span>
			</div>

			<div className="aboutBox" ref={aboutBoxRef}>
				<div className="chatBoxHeader">
					<div className="chatBoxTitle">
						<h3>
							About
						</h3>
					</div>

					<div className="chatCloseButton" onClick={aboutCloseClick}>
						<span className="material-symbols-outlined chatCloseIcon">
							close
						</span>
					</div>
				</div>

				<div className="aboutBoxContent">
					<h4>
						FaceLogix is an app that enables you to record attendance by using face recognition technque. The contributers of the project are: <br/>

						<div className="aboutBoxName"> Anuj Chowdhury </div>
						<div className="aboutBoxName"> Rishab Kashyap </div>
						<div className="aboutBoxName"> Hemendra Arya </div>
						<div className="aboutBoxName"> Hitesh Patel </div>
						<div className="aboutBoxName"> Kunal Yadav </div>
					</h4>
				</div>
			</div>
		</>
	);
}

export default About;